// components/ShareButton.tsx
import {
  FaFacebook,
  FaTwitter,
  FaWhatsapp,
  FaTelegramPlane,
  FaLinkedin,
} from 'react-icons/fa';
import PropTypes from 'prop-types';

export interface ShareButtonProps {
  url: string;
  title: string;
  description: string;
}

export const ShareButton: React.FC<ShareButtonProps> = ({
  url,
  title,
  description,
}) => {
  // Function for fallback share (Web Share API)
  const fallbackShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title,
          text: description,
          url,
        })
        .catch((error) => console.error('Error sharing:', error));
    } else {
      alert('Sharing is not supported in this browser.');
  }
  
  ShareButton.propTypes = {
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  };
  };

  return (
    <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
      {/* Facebook Button */}
      <a
        href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
        target="_blank"
        rel="noopener noreferrer"
        className="flex flex-col items-center justify-center bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-lg transition duration-300 w-40"
        aria-label="Share on Facebook"
      >
        <FaFacebook className="text-3xl mb-2" />
        <span>Share on Facebook</span>
      </a>

      {/* Twitter Button */}
      <a
        href={`https://twitter.com/intent/tweet?text=${title}&url=${url}`}
        target="_blank"
        rel="noopener noreferrer"
        className="flex flex-col items-center justify-center bg-blue-400 hover:bg-blue-500 text-white px-4 py-2 rounded-lg transition duration-300 w-40"
        aria-label="Share on Twitter"
      >
        <FaTwitter className="text-3xl mb-2" />
        <span>Share on Twitter</span>
      </a>

      {/* WhatsApp Button */}
      <a
        href={`https://wa.me/?text=${title} ${url}`}
        target="_blank"
        rel="noopener noreferrer"
        className="flex flex-col items-center justify-center bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-lg transition duration-300 w-40"
        aria-label="Share on WhatsApp"
      >
        <FaWhatsapp className="text-3xl mb-2" />
        <span>Share on WhatsApp</span>
      </a>

      {/* Telegram Button */}
      <a
        href={`https://telegram.me/share/url?url=${url}&text=${title}`}
        target="_blank"
        rel="noopener noreferrer"
        className="flex flex-col items-center justify-center bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg transition duration-300 w-40"
        aria-label="Share on Telegram"
      >
        <FaTelegramPlane className="text-3xl mb-2" />
        <span>Share on Telegram</span>
      </a>

      {/* LinkedIn Button */}
      <a
        href={`https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}&summary=${description}`}
        target="_blank"
        rel="noopener noreferrer"
        className="flex flex-col items-center justify-center bg-blue-700 hover:bg-blue-800 text-white px-4 py-2 rounded-lg transition duration-300 w-40"
        aria-label="Share on LinkedIn"
      >
        <FaLinkedin className="text-3xl mb-2" />
        <span>Share on LinkedIn</span>
      </a>

      {/* Fallback Share Button */}
      <button
        onClick={fallbackShare}
        className="flex flex-col items-center justify-center bg-gray-600 hover:bg-gray-700 text-white px-4 py-2 rounded-lg transition duration-300 w-40"
        aria-label="Other share options"
      >
        <span className="text-xl mb-2">🔗</span>
        <span>Share on Instagram</span>
      </button>
    </div>
  );
};