// src/pages/[slug].tsx
import { useRouter } from 'next/router';
import Head from 'next/head';
import { useState, useEffect } from 'react';
import { ShareButton } from '../components/ShareButton';
import Navbar from '../components/Navbar'; // Import Navbar
import Footer from '../components/Footer'; // Import Footer
import { GetServerSideProps } from 'next';

interface PageData {
  title: string;
  description: string;
  content: string;
}

interface DynamicPageProps {
  pageData: PageData | null;
  error: string | null;
}

export default function DynamicPage({ pageData, error }: DynamicPageProps) {
  const router = useRouter();
  const { slug } = router.query;

  // Construct current URL for SEO purposes
  const currentUrl = typeof window !== 'undefined' ? window.location.href : '';

  // Handle error state
  if (error) {
    return (
      <div className="container mx-auto px-4 py-16 text-center">
        <p className="text-lg sm:text-xl lg:text-2xl text-red-500">Error: {error}</p>
      </div>
    );
  }

  // If no data is found, display a "not found" message
  if (!pageData) {
    return (
      <div className="container mx-auto px-4 py-16 text-center">
        <p className="text-lg sm:text-xl lg:text-2xl">Page not found.</p>
      </div>
    );
  }

  // Return the dynamic page content
  return (
    <>
      {/* Dynamic Head for SEO */}
      <Head>
        <title>{pageData.title} | My Website</title>
        <meta name="description" content={pageData.description} />
        <link rel="canonical" href={currentUrl} />

        {/* Open Graph meta tags */}
        <meta property="og:title" content={pageData.title} />
        <meta property="og:description" content={pageData.description} />
        <meta property="og:url" content={currentUrl} />
        <meta property="og:type" content="website" />

        {/* Twitter meta tags */}
        <meta name="twitter:title" content={pageData.title} />
        <meta name="twitter:description" content={pageData.description} />
        <meta name="twitter:card" content="summary_large_image" />
      </Head>

      {/* Include Navbar */}
      <Navbar lng="en" />

      {/* Page Content */}
      <div className="container mx-auto px-4 py-16">
        {/* Page title */}
        <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-center mb-8">
          {pageData.title}
        </h1>

        {/* Share button */}
        <div className="flex justify-center mb-8">
          <ShareButton
            url={currentUrl}
            title={pageData.title}
            description={pageData.description}
          />
        </div>

        {/* Page content */}
        <div className="text-lg sm:text-xl lg:text-2xl text-justify">
          <p>{pageData.description}</p>
          <div className="mt-4">
            <p>{pageData.content}</p>
          </div>
        </div>
      </div>

      {/* Include Footer */}
      <Footer />
    </>
  );
}

// Fetch data server-side to improve performance and SEO
import { GetServerSidePropsContext } from 'next';

interface FetchResponse {
  ok: boolean;
  statusText: string;
  json: () => Promise<PageData>;
}

export const getServerSideProps: GetServerSideProps = async (context: GetServerSidePropsContext) => {
  const { slug } = context.params || {};

  try {
    // Simulated API call (replace with your actual API or CMS integration)
    // Fetch data from your backend or CMS
    const response: FetchResponse = await fetch(`https://your-api.com/api/pages/${slug}`);
    if (!response.ok) {
      throw new Error(`Failed to fetch page data: ${response.statusText}`);
    }

    const data: PageData = await response.json();

    // Return the page data as props
    return {
      props: {
        pageData: data,
        error: null,
      },
    };
  } catch (err) {
    return {
      props: {
        pageData: null,
        error: (err as Error).message,
      },
    };
  }
};